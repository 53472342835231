.navbar {
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #111;
}

.nav_right img {
  margin: 0 5px;
}

.logo {
  display: flex;
  align-items: center;
  height: 26px;
  justify-content: space-around;
}

.logo img {
  height: 100%;
}

.navcontent {
  padding: 5px;
  color: white;
  border-radius: 4px;
}

.navcontent:hover {
  color: #f878cd;
}

.brand {
  font-family: Dosis !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px;
  text-transform: uppercase;
  color: #303335;
  line-height: 23px;
  margin-bottom: 0px;
}

.navbar a {
  text-decoration: none;
  letter-spacing: 0.05em;
  color: #fff;
}
